import GoogleTagManager from '@/components/GoogleTagManager';
import { setSourceAttribution } from '@/lib/analytics';
import { localStorage } from '@/lib/localStorage';
import { ChakraProvider } from '@chakra-ui/react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Analytics as VercelAnalytics } from '@vercel/analytics/react';
import { AnimatePresence } from 'framer-motion';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';
import { AppProvider } from '../providers/AppProvider';
import '../styles/globals.scss';
import { theme } from '../theme';
import posthog from "posthog-js"
import { PostHogProvider } from 'posthog-js/react'

if (typeof window !== 'undefined') {
    const posthogKey = process.env.NEXT_PUBLIC_POSTHOG_KEY;
    if(!posthogKey) {
        throw new Error("PostHog key is not defined")
    }

    posthog.init(posthogKey, {
        api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://eu.i.posthog.com',
        person_profiles: 'identified_only',
        loaded: (posthog) => {
            if (process.env.NODE_ENV === 'development') posthog.debug()
        }
    })
}

export default function App({ Component, pageProps }: AppProps) {
    const env = process.env.NEXT_PUBLIC_APP_ENV;
    const router = useRouter();

    useEffect(() => {
        if (env === 'development') {
            console.log('💻 Development');
        } else if (env === 'production') {
            console.log('🚗 Clidriving 🚗');
        }
    }, [router.events, env]);

    useEffect(() => {
        const q = router.query;

        const now = new Date();
        if (router.isReady) {
            localStorage.set('first-pageview-time-ms', now.getTime());
            if (Object.keys(q).length !== 0) {
                localStorage.set('routerQuery', q);
            }
            setSourceAttribution(q);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.isReady]);

    useEffect(() => {
        localStorage.set('first-pageview-time-ms', new Date().getTime());
        const url = new URL(window.location.href);
        const referrer = document.referrer;
        function getMedium(referrer: string) {
            const SOURCE_MAP = ['google', 'bing', 'duckduckgo', 'yahoo', 'ask', 'aol', 'yandex'];
            return SOURCE_MAP.some((item) => referrer.includes(item)) ? 'organic' : 'referral';
        }
        function getSourceAttribution(url: URL) {
            return {
                Source: url.searchParams.get('utm_source') ?? (referrer || 'direct'),
                Medium: url.searchParams.get('utm_medium') ?? getMedium(referrer),
                Campaign: url.searchParams.get('utm_campaign') ?? '',
                ClickId: url.searchParams.get('click_id') ?? '',
            };
        }

        if (!localStorage.get('first-source-attribution')) {
            localStorage.set('first-source-attribution', getSourceAttribution(url));
        } else {
            localStorage.set('last-source-attribution', getSourceAttribution(url));
        }

        const handleRouteChange = () => posthog.capture('$pageview')
        router.events.on('routeChangeComplete', handleRouteChange)

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange)
        }
    }, []);

    const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;
    const OG_IMAGE = `${BASE_URL}/images/og.jpg`;
    const DESC =
        'Consigue un préstamo usando tu coche como garantía y manteniendo la titularidad. Sin complicaciones. Evaluamos tu coche al instante para ofrecerte la mejor oferta del mercado.';
    const TITLE = 'Clidrive - La Plataforma #1 de financiación de coches';

    const handleMetaInfo = () => {
        if (router.asPath.startsWith('/blog/')) {
            return <></>;
        }

        if (router.asPath.startsWith('/refinancia-prestamo-coche')) {
            return (
                <Head>
                    <meta lang="es" />
                    <title>Refinanciación de Préstamos de Vehículos - Ahorra Dinero</title>
                    <meta name="title" content="Refinanciación de Préstamos de Vehículos - Ahorra Dinero" />
                    <meta
                        name="description"
                        content="Refinancia tu préstamo de coche y ahorra en tus pagos mensuales. Obtén mejores tasas de interés y opciones de pago flexibles con nuestro proceso rápido y sencillo."
                    />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
                    <meta charSet="UTF-8" />
                    <meta content="index, follow" name="robots" />

                    <meta property="og:image" content={OG_IMAGE} />
                    <meta property="og:image:secure_url" content={OG_IMAGE} />
                    <meta property="og:image:width" content="700" />
                    <meta property="og:image:height" content="348" />
                    <meta property="og:image:type" content="image/png" />
                    <meta property="og:image:alt" content="Refinanciación de Préstamos de Vehículos - Ahorra Dinero" />
                    <meta property="og:site_name" content="Clidrive" />
                    <meta property="og:title" content="Refinanciación de Préstamos de Vehículos - Ahorra Dinero" />
                    <meta
                        property="og:description"
                        content="Refinancia tu préstamo de coche y ahorra en tus pagos mensuales. Obtén mejores tasas de interés y opciones de pago flexibles con nuestro proceso rápido y sencillo."
                    />
                    <meta property="og:url" content="https://www.clidrive.com" />
                    <meta property="og:type" content="website" />
                    <meta property="og:locale" content="es_ES" />
                    {/* Twitter */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="twitter:domain" content="clidrive.com" />
                    <meta property="twitter:url" content="https://www.clidrive.com" />
                    <meta name="twitter:title" content="Refinanciación de Préstamos de Vehículos - Ahorra Dinero" />
                    <meta
                        name="twitter:description"
                        content="Refinancia tu préstamo de coche y ahorra en tus pagos mensuales. Obtén mejores tasas de interés y opciones de pago flexibles con nuestro proceso rápido y sencillo."
                    />
                    <meta name="twitter:image" content={OG_IMAGE} />
                </Head>
            );
        }

        return (
            <Head>
                <meta lang="es" />
                <title>{TITLE}</title>
                <meta name="title" content={TITLE} />
                <meta name="description" content={DESC} />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
                <meta charSet="UTF-8" />
                <meta content="index, follow" name="robots" />

                <meta property="og:image" content={OG_IMAGE} />
                <meta property="og:image:secure_url" content={OG_IMAGE} />
                <meta property="og:image:width" content="700" />
                <meta property="og:image:height" content="348" />
                <meta property="og:image:type" content="image/png" />
                <meta property="og:image:alt" content={TITLE} />
                <meta property="og:site_name" content="Clidrive" />
                <meta property="og:title" content={TITLE} />
                <meta property="og:description" content={DESC} />
                <meta property="og:url" content="https://www.clidrive.com" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="es_ES" />
                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="clidrive.com" />
                <meta property="twitter:url" content="https://www.clidrive.com" />
                <meta name="twitter:title" content={TITLE} />
                <meta name="twitter:description" content={DESC} />
                <meta name="twitter:image" content={OG_IMAGE} />
            </Head>
        );
    };

    return (
        <>
            {handleMetaInfo()}
            <GoogleTagManager />
            <Script
                id="Cookiebot"
                src="https://consent.cookiebot.com/uc.js"
                data-cbid="6ef48cf1-f9c2-4bf7-8ac1-bf582f3060be"
                type="text/javascript"
            />
            <PostHogProvider client={posthog}>
                <ChakraProvider theme={theme}>
                    <AnimatePresence mode="wait" initial={false}>
                        <AppProvider>
                            <Component {...pageProps} />
                        </AppProvider>
                    </AnimatePresence>
                    <VercelAnalytics />
                </ChakraProvider>
            </PostHogProvider>

        </>
    );
}
